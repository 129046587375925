<template>
	<div>
		<Dialog :dialog="dialog" v-on:close="$emit('close', true)" :dialog-width="500">
			<template v-slot:title> Create New Product</template>
			<template v-slot:body>
				<v-form
					ref="inventoryForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row>
						<v-col md="3" class="my-auto py-0">
							<label for="name" class="btx-label fw-500 mt-2">Product Type</label>
						</v-col>
						<v-col md="9" class="py-0">
							<v-radio-group
								v-on:change="getSerialNumber()"
								class="ma-0"
								hide-details
								mandatory
								row
								v-model="new_product.product_type"
							>
								<v-radio color="blue darken-4" label="Piping" value="piping"></v-radio>
								<v-radio color="blue darken-4" label="Other items" value="other items"></v-radio>
							</v-radio-group>
						</v-col>
						<v-col md="12">
							<label for="name" class="btx-label fw-500 mt-2">Serial Number</label>
							<TextInput
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								:loading="pageLoading"
								id="serial-number"
								placeholder="Serial Number"
								:rules="[
									vrules.required(new_product.serial_number, 'Serial Number'),
									serialNumberError ? false : true,
								]"
								:class="{
									required: !new_product.serial_number,
								}"
								v-model="new_product.serial_number"
								v-on:change="
									validateSerialNumber(
										new_product.serial_number,
										new_product.product_type == 'piping' ? 'piping' : 'other-items'
									)
								"
							></TextInput>
							<span v-if="serialNumberError" class="red--text text--darken-1 font-small">{{
								serialNumberError
							}}</span>
						</v-col>
						<v-col md="12">
							<label for="product-name" class="btx-label fw-500 mt-2"> Name </label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="product-name"
								placeholder="Name"
								v-model="new_product.name"
								:rules="[vrules.required(new_product.name, 'Name')]"
								:class="{
									required: !new_product.name,
								}"
							>
							</TextInput>
						</v-col>
						<v-col md="12">
							<label for="group" class="btx-label fw-500 mt-2"> Category </label>
							<AutoCompleteInput
								hide-top-margin
								hide-details
								:items="groupList"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="group"
								:rules="[vrules.required(new_product.group, 'Category')]"
								:class="{
									required: !new_product.group,
								}"
								placeholder="Category"
								v-model="new_product.group"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageCategoryDialog = true"
							></AutoCompleteInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn :disabled="pageLoading" depressed tile @click="$emit('close', true)"> Close</v-btn>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="updateOrCreate()"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
		<ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LINEITEMS } from "@/core/services/store/lineitem.module";
import { CreateInventory } from "@/core/lib/inventory.lib";
import { ValidateSerialNumber, GenrateSerialNumber } from "@/core/lib/common.lib";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ManageCategory from "@/view/components/Manage-Category";
import Dialog from "@/view/components/Dialog";
export default {
	name: "product-create-shortcut",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		supplierId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			pageLoading: false,
			formValid: true,
			manageCategoryDialog: false,
			serialNumberError: null,
			groupList: [],
			new_product: {
				product_type: "piping",
				serial_number: null,
				name: null,
				group: null,
			},
		};
	},
	components: {
		TextInput,
		AutoCompleteInput,
		ManageCategory,
		Dialog,
	},

	methods: {
		getCategories(param) {
			this.groupList = param;
		},
		async updateOrCreate() {
			const _this = this;
			if (!this.supplierId) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Please Select Supplier First" }]);
				return false;
			}
			const formErrors = _this.validateForm(_this.$refs.inventoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.inventoryForm.validate()) {
				return false;
			}
			const formData = {
				product_type: this.new_product.product_type,
				name: this.new_product.name,
				serial_number: this.new_product.serial_number,
				category: this.new_product.group,
				suppliers: [
					{
						supplier_id: this.supplierId ? this.supplierId : null,
						part_number: null,
						price: 0,
					},
				],
			};
			try {
				_this.pageLoading = true;
				await CreateInventory(formData);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Product has been Created." },
				]);
				_this.$store.dispatch(SET_LOCAL_DB);
				_this.$store.dispatch(SET_LINEITEMS).then(() => {
					_this.$emit("close", true);
					_this.$emit("success", true);
				});
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		validateSerialNumber(serial_number, type) {
			const _this = this;
			const params = {
				type: type,
				serial_number: serial_number,
			};
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.serialNumberError = null;
				ValidateSerialNumber(`validate-serialnumber`, params)
					.then((output) => {
						if (output.exists) {
							_this.serialNumberError = `${serial_number} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		async getSerialNumber() {
			const _this = this;
			_this.serialNumberError = null;
			let type = _this.new_product.product_type == "piping" ? "piping" : "other-items";
			const { barcode } = await GenrateSerialNumber(type);
			_this.new_product.serial_number = barcode;
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.groupList = this.localDB("groups", []);
		this.getSerialNumber();
	},
};
</script>
